import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const HorizontalBarGraph = ({ data }) => {
    const d3Container = useRef(null);

    useEffect(() => {
        // Draw chart on initial render
        drawChart();

        // Handle window resize
        window.addEventListener("resize", drawChart);

        // Clean up
        return () => {
            window.removeEventListener("resize", drawChart);
        };
    }, [data]); // Redraw chart if data changes
    
    const drawChart = () => {
        if (data && d3Container.current) {
            // Clear SVG content before rendering new graph
            d3.select(d3Container.current).selectAll("*").remove();

            // Get the width of the parent container
            const containerWidth = d3Container.current.parentElement.offsetWidth;

            // Set the dimensions and margins of the graph
            const margin = { top: 10, right: 60, bottom: 0, left: 0 },
                  width = containerWidth - margin.left - margin.right,
                  height = 50 - margin.top - margin.bottom;

            const svg = d3.select(d3Container.current)
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

            // Set max value for x-scale
            const maxValue = 12000;

            // X scale
            const x = d3.scaleLinear()
                        .domain([0, maxValue])
                        .range([0, width]);

            // Y scale
            const y = d3.scaleBand()
                        .range([0, height])
                        .domain(data.map(d => d.name))
                        .padding(.1);

            // Bars
            svg.selectAll("myRect")
                .data(data)
                .enter()
                .append("rect")
                .attr("x", 16)
                .attr("y", d => y(d.name))
                .attr("width", d => x(d.value))
                .attr("height", y.bandwidth())
                .attr('rx', 5) // Adjust this value for desired roundness
                .attr("fill", d => {
                    if (d.name === "Graduate Monthly Income") {
                        return "#05BFDD"; // Bright blue for "Monthly Income"
                    } else if (d.name === "Graduate Debt Payment") {
                        return "#F92654"; // Vibrant red for "Debt Payment"
                    } else {
                        return "#69b3a2"; // Default color for other bars
                    }
                });

            // Format values as US dollars
            const dollarFormat = d3.format("$,.0f");

            // Append text elements for each bar
            svg.selectAll("myText")
                .data(data)
                .enter()
                .append("text")
                .attr("x", d =>  {
                    if (d.value === null) {
                        return 16;
                    } else {
                        return x(d.value) + 21;
                    }
                })
                     // Position text slightly beyond the end of each bar
                .attr("y", d => y(d.name) + y.bandwidth() / 2)
                .text(d => {
                    if (d.value === null) {
                        return "N/A";
                    } else {
                        return dollarFormat(d.value);
                    }
                }) // Format the text as US dollars
                .attr("fill", d => {
                    if (d.value === null) {
                        return "#ccc"
                    } else if (d.name === "Graduate Monthly Income") {
                        return "#05BFDD"; // Bright blue for "Monthly Income"
                    } else if (d.name === "Graduate Debt Payment") {
                        return "#F92654"; // Vibrant red for "Debt Payment"
                    } else {
                        return "#69b3a2"; // Default color for other bars
                    }
                })
                .attr("font-size", "12px")
                .attr("alignment-baseline", "middle");

             // Append bar names above each bar
             svg.selectAll(".bar-name")
                .data(data)
                .enter()
                .append("text")
                .attr("class", "bar-name")
                .attr("x", 16)
                .attr("y", d => y(d.name) - 5) // Position above each bar
                .text(d => d.name) // Set the text as the bar name
                .attr("fill", "#eee") // Text color
                .attr("font-size", "12px") // Font size
                .attr("font-style", "italic") // Make the font italicized
                .attr("text-anchor", "start"); // Align text at the start


        }
    };

    return (
        <svg
            className="d3-component"
            ref={d3Container}
        />
    );
}

export default HorizontalBarGraph;
