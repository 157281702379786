import useDocumentTitle from "../useDocumentTitle";

export default function PrivacyPolicyPage() {
    useDocumentTitle('Privacy Policy');

    return (
        <div className="pp-main">
            <h1>Privacy Policy</h1>
                <p>
                    We at CollegeNPV LLC ("CollegeNPV") respect your privacy and are strongly committed to keeping secure any information we obtain from you or about you. This Privacy Policy describes our practices with respect to Personal Information we collect from or about you when you use our website.
                </p>
            <h2>Personal information we collect</h2>
                <p>We collect personal information relating to you ("Personal Information") as follows:</p>
                <h3>Personal information you provide:</h3> 
                    <p>We collect Personal Information if you create an account to use our website or communicate with us as follows:</p>
                    <p>
                        <strong>Account Information:</strong> When you create an account with us, we will collect information associated with your account, including your contact information and user type.
                    </p>
                    <p>
                        <strong>Communication Information:</strong> If you communicate with us, we collect your name, contact information and the contents of any messages you send.
                    </p>
                    <p>
                        <strong>Social Media Information:</strong> We have pages on social media sites like Instagram, Facebook, X, Reddit and LinkedIn. When you interact with our social media pages, we will collect Personal Information that you elect to provide to us, such as your contact details. In addition, the companies that host our social media pages may provide us with aggregate information and analytics about our social media activity.
                    </p>
                    <p>
                        <strong>Other Information You Provide:</strong> We collect other information that you may provide to us, such as when you participate in surveys or provide us with information to establish your identity.
                    </p>
                <h3>Personal information we receive automatically from your use of our website</h3>
                    <p>When you visit, use, or interact with our website, we receive the following information about your visit, use or interactions ("Technical Information"):</p>
                    <p>
                        <strong>Log Data:</strong> Information that your browser or device automatically sends when you use our site. Log data includes your Internet Protocol address, browser type and settings, the date and time of your request, and how you interact with our site.
                    </p>
                    <p>
                        <strong>Usage Data:</strong> We may automatically collect information about your use of the site, such as the types of content that you view or engage with, the features you use and the actions you take, as well as your time zone, country, the dates and times of access, user agent and version, type of computer or mobile device, and your computer connection.
                    </p>
                    <p>
                        <strong>Device Information:</strong> Includes name of the device, operating system, device identifiers, and browser you are using. Information collected may depend on the type of device you use and its settings.
                    </p>
                    <p>
                        <strong>Cookies:</strong> We use cookies to operate and administer our site, and improve your experience. A “cookie” is a piece of information sent to your browser by a website you visit. You can set your browser to accept all cookies, to reject all cookies, or to notify you whenever a cookie is offered so that you can decide each time whether to accept it. However, refusing a cookie may in some cases preclude you from using, or negatively affect the display or function of, a website or certain areas or features of a website.
                    </p>
                    <p>
                        <strong>Analytics:</strong> We use a variety of online analytics products that use cookies to help us analyze how users use our site and enhance your experience when you use the site.
                    </p>
            <h2>How we use personal information</h2>
                <p>We may use Personal Information for the following purposes:</p>
                    <ul>
                        <li>To provide, administer, maintain and/or analyze the site;</li>
                        <li>To improve our site and conduct research;</li>
                        <li>To communicate with you; including to send you information about our site and events;</li>
                        <li>To develop new programs and services;</li>
                        <li>To prevent fraud, criminal activity, or misuses of our site, and to protect the security of our IT systems, architecture and networks;</li>
                        <li>To carry out business transfers; and</li>
                        <li>To comply with legal obligations and legal process and to protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or other third parties.</li>
                    </ul>
                <p>
                    <strong>Aggregated or De-Identified Information:</strong> We may aggregate or de-identify Personal Information so that it may no longer be used to identify you and use such information to analyze the effectiveness of our site, to improve and add features to our site, to conduct research and for other similar purposes. In addition, from time to time, we may analyze the general behavior and characteristics of users of our site and share aggregated information like general user statistics with third parties, publish such aggregated information or make such aggregated information generally available. We may collect aggregated information through the site, through cookies, and through other means described in this Privacy Policy. We will maintain and use de-identified information in anonymous or de-identified form and we will not attempt to reidentify the information, unless required by law.
                </p>
            <h2>Google AdSense</h2>
                <p>Our website uses Google AdSense, a web advertising service provided by Google Inc. ("Google"). Google AdSense uses cookies to serve ads based on your previous visits to our website or other websites.</p>
                    <ul>
                        <li>Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of cookies enables it and its partners to serve ads to our users based on their visit to our site and/or other sites on the Internet.</li>
                        <li>The information collected through these cookies will be used to help make the advertising more relevant to you and your interests.</li>
                        <li>Data may be used for purposes such as the personalization of ads (which involves showing users ads that are tailored to their interests).</li>
                        <li>Users may opt out of personalized advertising by visiting <strong><a href={`https://myadcenter.google.com/`} target="_blank">Ads Settings</a></strong>.</li>
                        <li>Alternatively, you can opt out of a third-party vendor's use of cookies for interest-based advertising by visiting <strong><a href={`https://youradchoices.com/`} target="_blank">aboutads.info</a></strong>.</li>
                    </ul>
            <h2>Disclosure of personal information</h2>
                <p>In certain circumstances we may provide your Personal Information to third parties without further notice to you, unless required by law:</p>
                    <ul>
                        <li>Vendors and Service Providers: To assist us in meeting business operations needs and to perform certain services and functions, we may provide Personal Information to vendors and service providers, including providers of hosting services, customer service vendors, cloud services, email communication software, web analytics services, and other information technology providers, among others. Pursuant to our instructions, these parties will access, process, or store Personal Information only in the course of performing their duties to us.</li>
                        <li>Business Transfers: If we are involved in strategic transactions, reorganization, bankruptcy, receivership, or transition of service to another provider (collectively, a “Transaction”), your Personal Information and other information may be disclosed in the diligence process with counterparties and others assisting with the Transaction and transferred to a successor or affiliate as part of that Transaction along with other assets.</li>
                        <li>Legal Requirements: We may share your Personal Information, including information about your interaction with our site, with government authorities, industry peers, or other third parties (i) if required to do so by law or in the good faith belief that such action is necessary to comply with a legal obligation, (ii) to protect and defend our rights or property, (iii) if we determine, in our sole discretion, that there is a violation of our terms, policies, or the law; (iv) to detect or prevent fraud or other illegal activity; (v) to protect the safety, security, and integrity of our products, employees, or users, or the public, or (vi) to protect against legal liability.</li>
                    </ul>
            <h2>Your rights</h2>
                <p>Depending on location, individuals may have certain statutory rights in relation to their Personal Information. For example, you may have the right to:</p>
                    <ul>
                        <li>Access your Personal Information and information relating to how it is processed.</li>
                        <li>Delete your Personal Information from our records.</li>
                        <li>Rectify or update your Personal Information.</li>
                        <li>Transfer your Personal Information to a third party (right to data portability).</li>
                        <li>Restrict how we process your Personal Information.</li>
                        <li>Withdraw your consent—where we rely on consent as the legal basis for processing at any time.</li>
                        <li>Object to how we process your Personal Information.</li>
                        <li>Lodge a complaint with your local data protection authority.</li>
                    </ul>
                <p>To exercise your rights, please submit a request through <strong>collegenpv@gmail.com.</strong></p>
            <h2>Children</h2>
                <p>Our site is not directed to children under the age of 13. CollegeNPV does not knowingly collect Personal Information from children under the age of 13.</p>
                <p>If you have reason to believe that a child under the age of 13 has provided Personal Information to CollegeNPV through the site, please email us at <strong>collegenpv@gmail.com.</strong> We will investigate any notification and if appropriate, delete the Personal Information from our systems. If you are 13 or older, but under 18, you must have permission from your parent or guardian to use our site.</p>
            <h2>Links to other websites</h2>
                <p>The site may contain links to other websites not operated or controlled by CollegeNPV, including social media services ("Third Party Sites"). The information that you share with Third Party Sites will be governed by the specific privacy policies and terms of service of the Third Party Sites and not by this Privacy Policy. By providing these links we do not imply that we endorse or have reviewed these sites. Please contact the Third Party Sites directly for information on their privacy practices and policies.</p>
            <h2>Security and retention</h2>
                <p>We implement commercially reasonable technical, administrative, and organizational measures to protect Personal Information both online and offline from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from us may not be secure. Therefore, you should take special care in deciding what information you send to us via the site or email. In addition, we are not responsible for circumvention of any privacy settings or security measures contained on the site, or third-party websites.</p>
                <p>We’ll retain your Personal Information for only as long as we need in order to provide our site to you, or for other legitimate business purposes such as resolving disputes, safety and security reasons, or complying with our legal obligations. How long we retain Personal Information will depend on a number of factors, such as the amount, nature, and sensitivity of the information, the potential risk of harm from unauthorized use or disclosure, our purpose for processing the information, and any legal requirements.</p>
            <h2>International users</h2>
                <p>By using our site, you understand and acknowledge that your Personal Information will be processed and stored in our facilities and servers in the United States and may be disclosed to our service providers and affiliates in other jurisdictions.</p>
                <p><strong>Legal Basis for Processing:</strong> Our legal bases for processing your Personal Information include:</p>
                    <ul>
                        <li>Performance of a contract with you when we provide and maintain our site. When we process Account Information, Content, and Technical Information solely to provide our Services to you, this information is necessary to be able to provide our Services. If you do not provide this information, we may not be able to provide our site to you.</li>
                        <li>Our legitimate interests in protecting our site from abuse, fraud, or security risks, or in developing, improving, or promoting our site. This may include the processing of Account Information, Content, Social Information, and Technical Information.</li>
                        <li>Your consent when we ask for your consent to process your Personal Information for a specific purpose that we communicate to you. You have the right to withdraw your consent at any time.</li>
                        <li>Compliance with our legal obligations when we use your Personal Information to comply with applicable law or when we protect our or our affiliates’, users’, or third parties’ rights, safety, and property.</li>
                    </ul>
                <p><strong>Data Transfers:</strong> Where required, we will use appropriate safeguards for transferring Personal Information outside of certain countries. We will only transfer Personal Information pursuant to a legally valid transfer mechanism.</p>
            <h2>Changes to the privacy policy</h2>
                <p>We may update this Privacy Policy from time to time. When we do, we will post an updated version on this page, unless another type of notice is required by applicable law.</p>
            <h2>How to contact us</h2>
                <p>Please contact us at <strong>collegenpv@gmail.com</strong> if you have any questions or concerns not already addressed in this Privacy Policy.</p>
        </div>
    );
}