import React, { useState, useEffect } from 'react';
import useDocumentTitle from "../useDocumentTitle";
import CollegeHeatmapD3 from '../CollegeHeatmapD3';

export default function CollegeHeatmap() {
    useDocumentTitle('College ROI Heatmap');
    const apiUrl = process.env.REACT_APP_API_URL || '';

    // State to hold the heatmap data
    const [heatmapData, setHeatmapData] = useState([]);

    // State to handle loading and error states
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the data when the component mounts
    useEffect(() => {
        const fetchHeatmapData = async () => {
            try {
                const response = await fetch(`${apiUrl}/heatmap`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                // Group the data by Field
                const groupedData = data.reduce((acc, item) => {
                    // Removing Comm Tech, too small to render in heatmap properly
                    if (item.CODE === 10 || item.CODE === 22) {
                        return acc; // Skip this item
                    }
                    // Find the group by 'Field' or create a new one if it doesn't exist
                    let group = acc.find(g => g.field === item.Field);
                    
                    if (!group) {
                        group = { field: item.Field, items: [] };
                        acc.push(group);
                    }
                    
                    // Push the current item into the appropriate group
                    group.items.push({
                        CIPDESC: item.CIPDESC,
                        total_count: item.total_count,
                        median_npv: item.median_npv,
                        CODE: item.CODE,
                        CIPCODE: item.CIPCODE,
                    });
                    
                    return acc;
                }, []);

                // Update the state with grouped data
                setHeatmapData(groupedData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchHeatmapData();
    }, []);

    // Render the data or loading/error states
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div  className="heatmap-main">
            <h1 style={{marginBottom: '5px',}}>College ROI Heatmap</h1>
            <p style={{marginBottom: '0px',}}>Size represents number of programs, color indicates median ROI of programs ranked in the respective field. Click on a field of study to view more detail.</p>
            <div>
                <CollegeHeatmapD3 heatmapData={heatmapData} />
            </div>
        </div>
    );
}
