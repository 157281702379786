import useDocumentTitle from "../useDocumentTitle"

export default function HomePage() {
    useDocumentTitle('College Rankings by Return on Investment');

    return (
        <div className="home-main">
            <div className="hero-section">
                <h1 className="hero-headline">Explore colleges that are <span className="hero-headlinehighlight">worth the investment.</span></h1>
                <button className="browse-button" onClick={() => window.location.href='/collegerankings?query=&page=1&sort=rank_desc'}>Browse Rankings</button>
                <div className="hero-programtitle-container">
                    <h2 className="hero-programtitle">Rankings by degree program <a className="hero-programtitle-link" href={`/programrankings?query=&page=1&sort=rank_desc`}>View all</a></h2>
                </div>
                <div className="hero-program">
                    <a className="hero-program-item" href={`/programrankings/?pcip=11&page=1&sort=rank_desc`}>
                        <div className="hero-program-item-emoji">👨‍💻</div>
                        <div className="hero-program-item-title">Computer Science</div>
                    </a>
                    <a className="hero-program-item" href={`/programrankings/?pcip=52&page=1&sort=rank_desc`}>
                        <div className="hero-program-item-emoji">💸</div>
                        <div className="hero-program-item-title">Business</div>
                    </a>
                    <a className="hero-program-item" href={`/programrankings/?pcip=14&page=1&sort=rank_desc`}>
                        <div className="hero-program-item-emoji">⚙️</div>
                        <div className="hero-program-item-title">Engineering</div>
                    </a>
                    <a className="hero-program-item" href={`/programrankings/?pcip=26&page=1&sort=rank_desc`}>
                        <div className="hero-program-item-emoji">🧬</div>
                        <div className="hero-program-item-title">Biology</div>
                    </a>
                    <a className="hero-program-item" href={`/programrankings/?pcip=42&page=1&sort=rank_desc`}>
                        <div className="hero-program-item-emoji">🧠</div>
                        <div className="hero-program-item-title">Psychology</div>
                    </a>
                    <a className="hero-program-item" href={`/programrankings/?pcip=13&page=1&sort=rank_desc`}>
                        <div className="hero-program-item-emoji">🏫</div>
                        <div className="hero-program-item-title">Education</div>
                    </a>
                    <a className="hero-program-item" href={`/programrankings/?pcip=9&page=1&sort=rank_desc`}>
                        <div className="hero-program-item-emoji">🖋️</div>
                        <div className="hero-program-item-title">Journalism</div>
                    </a>
                    <a className="hero-program-item" href={`/programrankings/?pcip=24&page=1&sort=rank_desc`}>
                        <div className="hero-program-item-emoji">🎭</div>
                        <div className="hero-program-item-title">Liberal Arts</div>
                    </a>
                </div>
            </div>
            <div className="benefits-section">
                <h1 className="benefits-headline">There's a good school for everyone.</h1>
                <ul className="benefits-list">
                    <li>1. Create a list of target schools.</li>
                    <li>2. Determine if schools are a reach, target, or safety.</li>
                    <li>3. Track your admissions status and scholarship awards.</li>
                </ul>
                <button className="explore-button" onClick={() => window.location.href='/register'}>Create Account</button>
            </div>
            <div className="hero-section2">
                <h1 className="hero-headline2">How we rank schools</h1>
                <p className="hero-sectionp">Learn how CollegeNPV ranks schools using analytical techniques employed by Wall Street investment banks.</p>
                <button className="explore-button" onClick={() => window.location.href='/methodology'}>Read Our Methodology</button>
            </div>
        </div>
    )
};