import React from 'react';
import { useState, useEffect } from 'react';

const FOSFilterModal = ({
  show,
  onClose,
  onApplyFilters,
  initialPCIP,
  initialCIP
}) => {
  const [selectedPCIP, setSelectedPCIP] = useState(initialPCIP);
  const [selectedCIP, setSelectedCIP] = useState(initialCIP);

  const apiUrl = process.env.REACT_APP_API_URL || '';

  const [cipList, setCipList] = useState([]); // Store all CIP codes
  const [cipLookup, setCipLookup] = useState([]);
  const [pcipLookup, setPcipLookup] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCipList, setFilteredCipList] = useState([]); // Store filtered CIP codes

 const [showFields, setShowFields] = useState(false);
 const [isFocused, setIsFocused] = useState(false);


  // Reset state when the modal is shown with new initial values
  useEffect(() => {
    setSelectedPCIP(initialPCIP);
    setSelectedCIP(initialCIP);
    setSearchTerm('');
  }, [initialPCIP, initialCIP, show]);

  useEffect(() => {
    // Fetch all CIP codes once and store them
    fetch(`${apiUrl}/ciplookup/`)
      .then(response => response.json())
      .then(data => {
        setCipList(data);
        const cipData = data.filter(item => item.CODE_TYPE === "CIP");
        const pcipData = data.filter(item => item.CODE_TYPE === "PCIP");
        setCipLookup(cipData);
        setPcipLookup(pcipData);
      });
  }, [apiUrl]);

  useEffect(() => {
    // Immediately return if search term is empty, keeping filteredCipList empty
    if (searchTerm.trim() === '') {
      setFilteredCipList([]);
      return;
    }
  
    const regex = new RegExp(searchTerm, 'i'); // Case-insensitive search
    // Filter cipList based on the search term and limit results to 10
    let filtered = cipList.filter(cip => 
      (cip.Field && regex.test(cip.Field.toString())) || 
      (cip.Code && regex.test(cip.Code.toString()))
    );
    
    // Then sort the filtered results by count in descending order
    filtered.sort((a, b) => b.Count - a.Count);
  
    // Finally, limit the results to 10
    filtered = filtered.slice(0, 10);
    console.log(filtered);
    setFilteredCipList(filtered);
  }, [searchTerm, cipList]);
  


  if (!show) {
    return null;
  }

  const handleClearFilters = () => {
    setSelectedPCIP('');
    setSelectedCIP('');
    setSearchTerm('');
    setShowFields(false);
  };

  const handleCIPChange = (e) => {
    setShowFields(true);
    setSelectedCIP(e.target.value);
    //setSelectedPCIP(''); //disable PCIP
  };

  const handleFilterCIP = (input) => {
    // Determine whether the function was called with an event or directly with a value
    const selectedValue = typeof input === 'string' ? input : input.target.value.toString();
    let filteredCipLookup;
  
    // Apply the specific filtering logic based on the length of selectedValue
    if (selectedValue.length === 2) {
      filteredCipLookup = cipList.filter(item => 
        item.CODE_TYPE === "CIP" && item.CODE.toString().length === 4 && item.CODE.toString().substring(0, 2) === selectedValue
      );
    } else {
      filteredCipLookup = cipList.filter(item => 
        item.CODE_TYPE === "CIP" && item.CODE.toString().length === 3 && item.CODE.toString().substring(0, 1) === selectedValue
      );
    }
  
    // Log and update the cipLookup state with the filtered results
    //console.log("Filtered CIPs based on selection:", filteredCipLookup);
    setCipLookup(filteredCipLookup);
  };
  

  const handlePCIPChangeAndResetSearch = (e) => {
    setSelectedPCIP(e.target.value);
    setShowFields(true);
    setSelectedCIP('');  // Call the existing PCIP change handler
    setSearchTerm('');    // Reset the search term
    handleFilterCIP(e);
  };

  const handleCIPChangeAndResetSearch = (e) => {
    handleCIPChange(e);  // Call the existing PCIP change handler
    setSearchTerm('');    // Reset the search term
  };

  const handleSelectionFromSearch = (codeString, codeType) => {
    if (codeType === 'PCIP') {
      handleFilterCIP(codeString);
      setSelectedPCIP(codeString);
      setShowFields(true);
      //console.log('PCIP Change!');
    } else if (codeType === 'CIP') {
      const prefix = codeString.length === 4 ? codeString.substring(0, 2) : codeString.substring(0, 1);
      handleFilterCIP(prefix); // Directly pass the relevant prefix
      setSelectedPCIP(prefix);
      setSelectedCIP(codeString);
      setShowFields(true);
      //console.log('CIP Change!');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>Filter Options</h2>
        { (selectedPCIP !== '' || selectedCIP !== 'None') && (
          <button className="clear-all-button" onClick={handleClearFilters}>
            Clear All
          </button>
        )}
        <div className="filter-options">
          <div className="filter-searchall">
            <label className="filterTitle" htmlFor="stateSelect">Search All Programs:</label>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setTimeout(() => setIsFocused(false), 100); // Delay losing focus
              }}
              placeholder="Search all fields (ex. 'business', 'history')..."
              id="programSearch"
            />
            {isFocused && filteredCipList.length > 0 && (
            <div className="filter-search-results-container">
              {filteredCipList.map((cip, index) => {
                // Find the start index of the match.
                const matchStartIndex = cip.Field.toLowerCase().indexOf(searchTerm.toLowerCase());
                const matchEndIndex = matchStartIndex + searchTerm.length;
                
                // Extract parts of the text.
                const beforeMatch = cip.Field.substring(0, matchStartIndex);
                const matchText = cip.Field.substring(matchStartIndex, matchEndIndex);
                const afterMatch = cip.Field.substring(matchEndIndex);
                
                return (
                  <div className="filter-search-results" key={index} onClick={() => {
                    setSearchTerm(cip.Field);

                      const codeString = cip.CODE.toString();
                      handleSelectionFromSearch(codeString, cip.CODE_TYPE);      
                  }}>
                    {/* Render the parts of the text, highlighting the match */}
                    {beforeMatch}<strong>{matchText}</strong>{afterMatch}
                  </div>
                );
              })}
            </div>
            )}
          </div>
          <div className="filter-selectprogram">
            <label className="filterTitle" htmlFor="stateSelect">Areas of Study:</label>
            <select id="stateSelect" value={selectedPCIP} onChange={handlePCIPChangeAndResetSearch}>
              <option value="">Select...</option>
                {pcipLookup.sort((a, b) => a.Field.localeCompare(b.Field)) // Sort alphabetically by the Field description
                  .map((item) => (
                    <option className="filterValue" key={item.CODE} value={item.CODE}>
                      {item.Field}
                    </option>
                  ))}
            </select>
          </div>
          {showFields && (
          <div>
            <label className="filterTitle" htmlFor="stateSelect">Fields of Study:</label>
            <select id="stateSelect" value={selectedCIP} onChange={handleCIPChangeAndResetSearch}>
              <option value="">Select...</option>
                {cipLookup.sort((a, b) => a.Field.localeCompare(b.Field)) // Sort alphabetically by the Field description
                  .map((item) => (
                    <option className="filterValue" key={item.CODE} value={item.CODE}>
                      {item.Field}
                    </option>
                  ))}
            </select>
          </div>
          )}
        </div>
        <button className="apply-button" onClick={() => onApplyFilters(selectedPCIP, selectedCIP)}>Apply Filters</button>
      </div>
    </div>
  );
};

export default FOSFilterModal;
