import './App.css';
//import College from './college';
//import Header from './header';
import Layout from './Layout';
import {Route, Routes} from "react-router-dom";
import IndexPage from './pages/IndexPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import {UserContextProvider} from './UserContext';
import CreatePost from './pages/CreatePost';
import PostPage from './pages/PostPage';
import EditPost from './pages/EditPost';
import AboutPage from './pages/AboutPage';
import CollegeRankings from './pages/CollegeRankings';
import CollegeFOSRankings from './pages/CollegeFieldOfStudyRankings';
import CollegePage from './pages/CollegePage';
import CollegeListBuilder from './pages/CollegeListBuilder';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/PageNotFound';
import MethodologyPage from './pages/MethodologyPage';
import AccountPage from './pages/AccountPage';
import TermsAndConditionsPage from './pages/TandCPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import CollegeHeatmap from './pages/CollegeHeatmap';

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path='/account' element={<AccountPage />} />
          {/* <Route path='/about' element={<AboutPage />} /> */}
          <Route path='/collegerankings' element={<CollegeRankings />} />
          <Route path='/programrankings' element={<CollegeFOSRankings />} />
          {/* <Route path ='/create' element={<CreatePost />} />
          <Route path='post/:title/:id' element={<PostPage />} />
          <Route path='/edit/:title/:id' element={<EditPost />} /> */}
          <Route path='/collegepage' element={<CollegePage />} />
          <Route path='/collegeroiheatmap' element={<CollegeHeatmap />} />
          <Route path='/collegelistbuilder' element={<CollegeListBuilder />} />
          {/* <Route path = '/blog' element={<IndexPage />} /> */}
          <Route path = '/methodology' element={<MethodologyPage />} />
          {/* <Route path="/termsandconditions" element={<TermsAndConditionsPage />} /> */}
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/not-found" element={<NotFoundPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </UserContextProvider>

  );
}

export default App;
