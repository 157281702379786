import React from 'react';
import { useState, useEffect } from 'react';

const US_STATES = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']

const FilterModal = ({
  show,
  onClose,
  onApplyFilters,
  initialState,
  initialTestScoreType = 'None', // Default to 'None' if not provided
  initialTargetScore = 20, // Default to 20 if not provided
}) => {
  const [selectedState, setSelectedState] = useState(initialState);
  const [testScoreType, setTestScoreType] = useState(initialTestScoreType);
  const [targetScore, setTargetScore] = useState(initialTargetScore);

  // Reset state when the modal is shown with new initial values
  useEffect(() => {
    setSelectedState(initialState);
    setTestScoreType(initialTestScoreType);
    setTargetScore(initialTargetScore);
  }, [initialState, initialTestScoreType, initialTargetScore, show]);


  if (!show) {
    return null;
  }

  const handleClearFilters = () => {
    setSelectedState('');
    setTestScoreType('None');
    setTargetScore(20);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const handleTestScoreTypeChange = (e) => {
    setTestScoreType(e.target.value);
    // Reset target score when changing test type
    if (e.target.value === 'SAT') {
      setTargetScore(1000); // Default score for SAT
    } else if (e.target.value === 'ACT') {
      setTargetScore(20); // Default score for ACT, adjust as necessary
    } else {
      setTargetScore(20); // Reset to a neutral value or any default you see fit for 'None'
    }
  };

  const handleScoreChange = (e) => {
    setTargetScore(e.target.value);
  };

  const renderScoreSlider = () => {
    if (testScoreType === 'None') {
      return null;
    }

    let maxScore = testScoreType === 'ACT' ? 36 : 1600; // Max score for ACT and SAT
    let minScore = testScoreType === 'ACT' ? 1 : 400; // Min score for ACT and SAT
    let step = testScoreType === 'ACT' ? 1 : 50;

    return (
      <div>
        <label className="filterTitle" htmlFor="scoreSlider">Target {testScoreType} Score: {targetScore}</label>
        <input
          id="scoreSlider"
          type="range"
          min={minScore}
          max={maxScore}
          step={step}
          value={targetScore}
          onChange={handleScoreChange}
          className="slider"
        />
      </div>
    );
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>Filter Options</h2>
        { (selectedState !== '' || testScoreType !== 'None') && (
          <button className="clear-all-button" onClick={handleClearFilters}>
            Clear All
          </button>
        )}
        <div className="filter-options">
          <div>
            <label className="filterTitle" htmlFor="stateSelect">State:</label>
            <select id="stateSelect" value={selectedState} onChange={handleStateChange}>
              <option value="">Select...</option>
              {US_STATES.map((state, index) => (
                <option className="filterValue" key={index} value={state}>{state}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="filterTitle" htmlFor="testScoreTypeSelect">Test Score Target:</label>
            <select id="testScoreTypeSelect" value={testScoreType} onChange={handleTestScoreTypeChange}>
              <option value="None">None</option>
              <option value="ACT">ACT</option>
              <option value="SAT">SAT</option>
            </select>
          </div>
          {renderScoreSlider()}
        </div>
        <button className="apply-button" onClick={() => onApplyFilters(selectedState, testScoreType, targetScore)}>Apply Filters</button>
      </div>
    </div>
  );
};

export default FilterModal;
