import { ReactComponent as Icon} from '../src/icon.svg'
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';

export default function CollegeFOSRank({UNITID,
                                        RANK,
                                        INSTNM,
                                        PCIP,
                                        CIPCODE,
                                        CIPDESC,
                                        NET_NPV,
                                        EARN_MDN_4YR,
                                        DEBT_ALL_STGP_ANY_MDN,
                                        OVERALL_RANK,
                                        RANKED_OVERALL,
                                        FIELD_RANK,
                                        RANKED_INFIELD,
                                        AREA_RANK,
                                        RANKED_INAREA
                                    }) {

    const apiUrl = process.env.REACT_APP_API_URL || '';
    const blobUrl = process.env.REACT_APP_BLOB_URL || 'https://collegenpvcloudstoredev.blob.core.windows.net/images';

    //Init states
    const [city,setCity] = useState('');
    const [state,setState] = useState('');
    const [c150,setC150] = useState();
    const [showTooltip, setShowTooltip] = useState(false);
    const [income,setIncome] = useState(EARN_MDN_4YR);
    const [debt,setDebt] = useState();

    useEffect(() => {
        fetch(`${apiUrl}/college/${UNITID}`)
            .then(response => {
                response.json().then(uniOutcomeData => {
                    //console.log(uniOutcomeData);
                    setCity(uniOutcomeData.CITY);
                    setState(uniOutcomeData.STABBR);
                    setC150(uniOutcomeData.C150);
                    setDebt(DEBT_ALL_STGP_ANY_MDN || uniOutcomeData.GRAD_DEBT_MDN);
                });
            });
    }, []);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        }).format(value);
    };

    function formatNumberWithCommas(number) {
        return number ? number.toLocaleString() : "";
    }

    function formatNumberAsPercentage(number, decimalPlaces = 0) {
        if (number !== null && number !== undefined) {
            return `${(number * 100).toFixed(decimalPlaces)}%`;
        }
        return "N/A";
    }

    // Dynamic image URL based on UNITID
    const imageUrl = `${UNITID}.jpg`;

    const navigate = useNavigate();

    const navigateAndScrollToTop = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
    };

    const pcipEmojiMap = {
        1: "🚜",
        3: "🏞️",
        4: "📐",
        5: "🌈",
        9: "🖋️",
        10: "👄",
        11: "👨‍💻",
        13: "🏫",
        14: "⚙️",
        15: "👩‍🔧",
        16: "🈸",
        19: "👪",
        22: "⚖️",
        23: "🇬🇧",
        24: "🎭",
        26: "🧬",
        27: "🔢",
        30: "📚",
        31: "🚴",
        38: "☯️",
        39: "✝️",
        40: "🚀",
        42: "🧠",
        43: "🔫",
        44: "🦮",
        45: "♟️",
        46: "👷",
        47: "🛠️",
        49: "🚢",
        50: "🎨",
        51: "👩‍⚕️",
        52: "💸",
        54: "🏺"
    }

    function getPCIPEmoji(pcipCode) {
        const codeAsInt = parseInt(pcipCode, 10);
    
        // Check if the pcipCode exists in the map
        if (pcipEmojiMap.hasOwnProperty(codeAsInt)) {
            return pcipEmojiMap[codeAsInt];
        }
    
        // Return a default emoji or null if not found
        return null; // Or return a default emoji like "📚" for not found
    }

    const fontColor = NET_NPV === null ? '#ccc' : (NET_NPV >= 0 ? '#05BFDD' : '#F92654');


    const overallRank = OVERALL_RANK === null ? 'N/A' : `#${formatNumberWithCommas(OVERALL_RANK)}`;
    const areaRank = AREA_RANK === null ? 'N/A' : `#${formatNumberWithCommas(AREA_RANK)}`;
    const fieldRank = FIELD_RANK === null ? 'N/A' : `#${formatNumberWithCommas(FIELD_RANK)}`;

    const overallPct = OVERALL_RANK === null ? '' : `Top (${formatNumberAsPercentage(OVERALL_RANK/RANKED_OVERALL,1)})`;
    const areaPct = AREA_RANK === null ? '' : `Top (${formatNumberAsPercentage(AREA_RANK/RANKED_INAREA,1)})`;
    const fieldPct = FIELD_RANK === null ? '' : `Top (${formatNumberAsPercentage(FIELD_RANK/RANKED_INFIELD,1)})`;

    //Tooltip Function
    function toggleTooltip() {
        setShowTooltip(!showTooltip);
    }

    return (
        <div>
            <div className="fos-wrapper">
                <div className="fos-card">
                    <div className="fos-card-top">
                        <div className="fos-card-ranking">
                        {OVERALL_RANK !== null ? `#${formatNumberWithCommas(OVERALL_RANK)} in expected outcomes` : "Unranked"}
                        </div>
                    </div>
                    <div className="fos-horizontal-divider"></div>
                    <div className="fos-card-info">
                        <div className="fos-card-info-left">
                            <h3 className="fos-name">{INSTNM}</h3>
                            <div className="fos-location">{city}, {state}</div>
                            <div className="fos-major">{getPCIPEmoji(PCIP)} {CIPDESC.replace(/\./g, '')}</div>
                        </div>
                        <div className="fos-card-info-right">
                            <a 
                                href={`/collegepage?INSTNM=${encodeURIComponent(INSTNM)}&UNITID=${encodeURIComponent(UNITID)}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigateAndScrollToTop(`/collegepage?INSTNM=${encodeURIComponent(INSTNM)}&UNITID=${encodeURIComponent(UNITID)}`);
                                }}
                                aria-label={`View more information about ${INSTNM}`}
                            >
                                <LazyLoadImage className="fos-card-img" 
                                    src={`${blobUrl}/${imageUrl}`} 
                                    alt={`${INSTNM} Image`}
                                    effect="blur"
                                    onError={(e) => {
                                        if (e.target.src !== `${blobUrl}/default.jpg`) {
                                            e.target.onerror = null; // Prevent further triggers of onError
                                            e.target.src = `${blobUrl}/default.jpg`; // Fallback image path
                                        }
                                    }}
                                    visibleByDefault={false}
                                    threshold={100}
                                />
                            </a>
                        </div>
                    </div>
                    <div className="fos-valuation">
                        <div className="fos-valuation-section">
                            <span className="fos-valuation-type">Return On Investment:</span>
                            <span className="fos-valuation-value"
                                onClick={toggleTooltip}
                                style={{ color: fontColor }}
                            >
                                {NET_NPV !== null ? formatCurrency(NET_NPV) : "N/A"} 
                                <span> <Icon style={{ fill: fontColor }} alt="Valuation information" /></span>
                                {showTooltip && (
                                <div
                                    className={`tooltip ${NET_NPV >= 0 ? 'tooltip-after-positive' : 'tooltip-after-negative'}`}
                                    style={{ backgroundColor: fontColor, borderColor: fontColor }}
                                >
                                    <div className="tooltip-title">What drives this estimate?</div>
                                    <div className="item2">
                                        <span className="label2">University Completion Rate:</span>
                                        <span className="value2">{formatNumberAsPercentage(c150)}</span>
                                    </div>
                                    <div className="item2">
                                        <span className="label2">Median Graduate Income:</span>
                                        <span className="value2">{formatCurrency(income)}</span>
                                    </div>
                                    <div className="item2">
                                        <span className="label2">Median Debt:</span>
                                        <span className="value2">{formatCurrency(debt)}</span>
                                    </div>
                                    <div className="methodology-desc">
                                        <p>
                                            For more information, read our <a className="methodology-link" href="/methodology">methodology</a>.
                                        </p>
                                    </div>
                                </div>
                                )}
                            </span>
                        </div>
                        <div className="calculation2-header">
                            <img src={`${apiUrl}/collegeimages//cnpv_favicon.png`} alt="Brand Logo" className="fos-brandmark" />
                        </div>
                    </div>
                    <div className="fos-rank-section">
                        <div className="fos-item2">
                            <span className="fos-label2">Overall Rank:</span>
                            <span className="fos-value2">{overallRank}</span>
                            <span className="fos-valuepct">{overallPct}</span>
                        </div>
                        <div className="fos-item2">
                            <span className="fos-label2">Area of Study:</span>
                            <span className="fos-value2">{areaRank}</span>
                            <span className="fos-valuepct">{areaPct}</span>
                        </div>
                        <div className="fos-item2">
                            <span className="fos-label2">Field of Study:</span>
                            <span className="fos-value2">{fieldRank}</span>
                            <span className="fos-valuepct">{fieldPct}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}