import { useState, useContext } from "react";
import {Navigate} from "react-router-dom";
import { UserContext } from "../src/UserContext";

export default function LoginComponent() {
    const [email, setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [redirect,setRedirect] = useState(false);
    const {setUserInfo} = useContext(UserContext);
    const apiUrl = process.env.REACT_APP_API_URL || '';

    async function login(ev) {
        ev.preventDefault();
        const response = await fetch(`${apiUrl}/login`, {
            method: 'POST',
            body: JSON.stringify({ email, password }), // Changed to email
            headers: {'Content-Type':'application/json'},
            credentials: 'include',
        });

        if (response.ok) {
            response.json().then(userInfo => {
                setUserInfo(userInfo);
                setRedirect(true);
            })
        } else {
            // It might be more appropriate to parse and display the actual error message from the response
            alert('Login failed. Please check your credentials and try again.');
        }
    }
    
    if (redirect) {
        return <Navigate to={'/collegelistbuilder'} />
    }

    return (
        <div className="login-main">
            <form className="login" onSubmit={login}>
                <h1>Login</h1>
                <input type="email"
                    placeholder="Email"
                    value={email}
                    onChange={ev => setEmail(ev.target.value)}
                    className="user-email"
                    required
                />
                <input type="password" 
                    placeholder="Password"
                    value={password} 
                    onChange={ev => setPassword(ev.target.value)}
                    className="user-password"
                    required
                />
                <button className="login-button">Login</button>
            </form>
        </div>
    );
}