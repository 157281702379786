import { useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import {Navigate} from "react-router-dom";
import { UserContext } from "../src/UserContext";

export default function RegisterComponent() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [userType, setUserType] = useState('');
    const [redirect,setRedirect] = useState(false);
    const {setUserInfo} = useContext(UserContext);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL || '';

    // Password validation function
    const validatePassword = (password) => {
        const hasLetters = /[a-zA-Z]/.test(password);
        const hasNumbers = /\d/.test(password);
        return password.length >= 6 && hasLetters && hasNumbers;
    };    

    async function register(ev) {
        ev.preventDefault();
        if (!validatePassword(password)) {
            setPasswordError('Password must be at least 6 characters and contain both letters and numbers.');
            return;
        }

        // Attempt to register the user
        const registerResponse = await fetch(`${apiUrl}/register`, {
            method: 'POST',
            body: JSON.stringify({ email, password, userType }),
            headers: {'Content-Type':'application/json'},
        });

        if (registerResponse.ok) {
            // Automatically log the user in upon successful registration
            const loginResponse = await fetch(`${apiUrl}/login`, {
                method: 'POST',
                body: JSON.stringify({ email, password }),
                headers: {'Content-Type':'application/json'},
                credentials: 'include', // Important for session-based authentication
            });
            if (loginResponse.ok) {
                loginResponse.json().then(userInfo => {
                    setUserInfo(userInfo);
                    setRedirect(true);
                });
            } else {
                alert('Login failed after registration.');
            }
        } else {
            registerResponse.json().then(error => {
                alert(`Registration failed: ${error.message}`);
            });
        }
    }

    if (redirect) {
        return <Navigate to={'/'} />
    }

    return (
        <div className="register-main">
            <form className="register" onSubmit={register}>
                <h1>Register</h1>
                <div className="register-benefits">
                    <div className="register-benefits-headline">Sign up for free.</div>
                    <ul className="register-benefits-list">
                        <li>1. Create a list of target schools.</li>
                        <li>2. Determine if schools are a reach, target, or safety.</li>
                        <li>3. Track your admissions status and scholarship awards.</li>
                    </ul>
                </div>
                <select
                    value={userType}
                    onChange={ev => setUserType(ev.target.value)}
                    required
                    className="user-type"
                >
                    <option value="">I am a...</option>
                    <option value="student">I am a student</option>
                    <option value="parent">I am a parent</option>
                </select>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={ev => setEmail(ev.target.value)}
                    required
                    className="user-email"
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={ev => setPassword(ev.target.value)}
                    required
                    className="user-password"
                />
                {passwordError && <div style={{color: 'red'}}>{passwordError}</div>}
                <div className="register-disclosure">By proceeding, I agree to CollegeNPV's&nbsp;
                    {/* <a href={`/termsandconditions`}>Terms and Conditions</a> and  */}
                    <a href={`/privacy`}>Privacy Policy</a>.</div>
                <button className="register-button">Create Account</button>
            </form>
        </div>
    );
}