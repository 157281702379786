import React from 'react';
import useDocumentTitle from '../useDocumentTitle';

function NotFoundPage() {
  useDocumentTitle('Page Not Found');

  return (
    <div style={{ color: '#000933', backgroundColor: '#FFFFFF', padding: '20px' }}>
      <h1 style={{ color: '#F92654' }}>Oops! Page not found.</h1>
      <p>We can't seem to find the page you're looking for.</p>
      <p>Here are some helpful links instead:</p>
      <ul>
        <li><a href="/">Home</a></li>
        <li><a href='/collegerankings?query=&page=1'>Browse Rankings</a></li>
      </ul>
    </div>
  );
}

export default NotFoundPage;