import { useNavigate } from 'react-router-dom';
import { useState, useContext } from 'react';
import { UserContext } from "./UserContext";

export default function CollegePageCard ({
    INSTNM,
    UNITID,
    currAlias,
    currName,
    currURL,
    currRank
}) {

    const apiUrl = process.env.REACT_APP_API_URL || '';
    const blobUrl = process.env.REACT_APP_BLOB_URL || 'https://collegenpvcloudstoredev.blob.core.windows.net/images';
    const imageUrl = `${UNITID}.jpg`;

    function formatURL(url) {
        if (!/^https?:\/\//i.test(url)) {
          return `http://${url}`;
        }
        return url;
    };

    const navigate = useNavigate();

    const { userInfo } = useContext(UserContext);

    const handleCardPlusClick = (UNITID) => {
        if (!userInfo) { // Check if user is not logged in
            navigate('/register');
        } else {
            // User is logged in, proceed with adding to 'My List'
            fetch(`${apiUrl}/save-college`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Important for sending cookies with the request
                body: JSON.stringify({ UNITID }),
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Failed to save college. Please try again.');
            })
            .then(data => {
                console.log("College saved successfully:", data);
                // Optionally, update UI or state to reflect the added college
            })
            .catch(error => {
                console.error("Error saving college:", error);
                // Handle error (e.g., show error message to user)
            });
        }
    };

    return (
        <div className="cp-container-csp">
            <div className="cp-card">
                <div className="cp-card-top">
                    <div className="cp-card-ranking">
                        {currRank !== null ? `#${currRank} in expected outcomes` : "Unranked"}
                    </div>
                    <div>
                        {!userInfo && <button className="cp-card-plus" onClick={() => handleCardPlusClick(UNITID)}>Sign Up</button>}
                        {userInfo && <button className="cp-card-plus" onClick={() => handleCardPlusClick(UNITID)}>+ My List</button>}
                    </div>
                </div>
                <img className="cp-card-img" 
                            src={`${blobUrl}/${imageUrl}`} 
                            alt={`${INSTNM} Image`}
                            onError={(e) => {
                                if (e.target.src !== `${blobUrl}/default.jpg`) {
                                    e.target.onerror = null; // Prevent further triggers of onError
                                    e.target.src = `${blobUrl}/default.jpg`; // Fallback image path
                                }
                            }}
                />
                {currAlias && !currAlias.includes(' ') ? ( //Only one word aliases
                    <h2 className="cp-card-college">{currName} <span>({currAlias})</span></h2>
                ) : (
                    <h2 className="cp-card-college">{currName}</h2>
                )}
                {/* {currURL && (
                    <a className="csp-link" href={formatURL(currURL)} target="_blank">
                        Visit Site
                    </a>
                )} */}
            </div>
        </div>
    );
}