
export default function CollegePageSelectivity ({
    currName,
    currAlias,
    currADM,
    rawADM,
    act25,
    act75,
    sat25,
    sat75
}) {

    function mapSelectivity(currADM) {
        if (currADM < 0.1) {
            return "is extremely selective";
        } else if (currADM >= 0.1 && currADM < 0.2) {
            return "is very selective";
        } else if (currADM >= 0.2 && currADM < 0.5) {
            return "is selective";
        } else if (currADM >= 0.5 && currADM < 0.8) {
            return "is moderately selective";
        } else if (currADM >= 0.8) {
            return "is less selective";
        } else {
            return "selectivity unknown"; // Handle cases where currADM is not available
        }
    };

    function niceCheck(currADM) {
        if (currADM >= 0.69 && currADM < 0.7) {
            return " (nice)"
        } else {
            return ""
        }
    };
      

    return (
        <div className="selectivity-container">
            {currAlias && !currAlias.includes(' ') ? ( //Only one word aliases
                        <h3>Is {currAlias} hard to get into?</h3>
                    ) : (
                        <h3>Selectivity</h3>
                    )}
                <p>
                    {currAlias && !currAlias.includes(' ') ? ( //Only one word aliases
                        <span>{currAlias}</span>
                    ) : (
                        <span>{currName}</span>
                    )}
                        {currADM && (
                            <span>&nbsp;{mapSelectivity(rawADM)}, with an acceptance rate of <strong>{currADM}.</strong> {niceCheck(rawADM)}</span>
                        )}
                        {!currADM && (
                            <span>&nbsp;does not have available acceptance rate data.</span>
                        )}
                </p>
            <h4>Test Score Range (25th-75th Percentile)</h4>
                <div className="cp-outcome-metrics">
                    <div>
                        <div>ACT Range:</div>
                        <div className="cp-outcome-metric-value">{act25}-{act75}</div>
                    </div>
                    <div>
                        <div>SAT Range:</div>
                        <div className="cp-outcome-metric-value">{sat25}-{sat75}</div>
                    </div>
                </div>
        </div>
    );
}