import useDocumentTitle from "../useDocumentTitle";
import RegisterComponent from "../RegisterComponent";

export default function RegisterPage() {
    useDocumentTitle('Register');

    return (
        <>
            <RegisterComponent />
            <div className="register-login-reroute">Already have an account? <a className="register-login-reroute-link" href={`/login`}>Log In</a></div>
        </>
    );
}