import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const BarGraph = ({ data }) => {
    const d3Container = useRef(null);

    useEffect(() => {
        if (data && d3Container.current) {
            const svg = d3.select(d3Container.current);

            // Clear the SVG to prevent duplication
            svg.selectAll("*").remove();

            // Set up scales and dimensions
            const margin = { top: 20, right: 20, bottom: 30, left: 20 }; // Adjust left margin as needed
            const width = 350 - margin.left - margin.right;
            const height = 350 - margin.top - margin.bottom;

            const x = d3.scaleBand()
                .range([0, width])
                .padding(0.1);
            const y = d3.scaleLinear()
                .range([height, 0]);

            svg.attr('width', width + margin.left + margin.right)
                .attr('height', height + margin.top + margin.bottom)

            const g = svg.append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`);


            // Process data
            x.domain(data.map(d => d.key));
            y.domain([0, d3.max(data, d => d.value)]);

            // Define the gradients within the useEffect hook to ensure they are added
            // every time the SVG is cleared and elements are re-appended
            const defs = svg.append('defs');
            defs.append("linearGradient")
                .attr("id", "income-gradient")
                .attr("x1", "0").attr("x2", "0")
                .attr("y1", "0").attr("y2", "1")
                .selectAll("stop")
                .data([
                    {offset: "0%", color: "#10E4F1"},
                    {offset: "100%", color: "#02222C"}
                ])
                .enter().append("stop")
                .attr("offset", function(d) { return d.offset; })
                .attr("stop-color", function(d) { return d.color; });

            defs.append("linearGradient")
                .attr("id", "debt-gradient")
                .attr("x1", "0").attr("x2", "0")
                .attr("y1", "0").attr("y2", "1")
                .selectAll("stop")
                .data([
                    {offset: "40%", color: "#F93245"},
                    {offset: "100%", color: "#F93245"}
                ])
                .enter().append("stop")
                .attr("offset", function(d) { return d.offset; })
                .attr("stop-color", function(d) { return d.color; });


            // Draw bars
            g.selectAll('.bar')
                .data(data)
                .enter().append('rect')
                .attr('class', 'bar')
                .attr('fill', d => {
                    if (d.key === 'Income') return 'url(#income-gradient)';
                    if (d.key === 'Debt') return 'url(#debt-gradient)';
                    if (d.key === 'On Campus') return 'url(#debt-gradient)';
                    if (d.key === 'Off Campus') return 'url(#debt-gradient)';
                    if (d.key === 'At Home') return 'url(#debt-gradient)';
                    // Add more conditions as needed
                    return '#F93245'; // Default color if no key matches
                })
                .attr('rx', 5) // Adjust this value for desired roundness
                .attr('x', d => x(d.key))
                .attr('width', x.bandwidth())
                .attr('height', 0) // Start with height 0
                .attr('y', height) // Start from the bottom
                .transition()
                .duration(800)
                .attr('y', d => y(d.value))
                .attr('height', d => height - y(d.value));
            
            // Add text for values
            g.selectAll('.bar-value')
                .data(data)
                .enter().append('text')
                .attr('class', 'bar-value')
                .attr('x', d => x(d.key) + x.bandwidth() / 2) // Position in the center of the bar
                .attr('y', d => y(d.value) - 5) // Position above the top of the bar
                .attr('text-anchor', 'middle') // Center the text
                .text(d => {
                    if (d.value === 0) return "N/A";
                    // Format the number as USD currency
                    return d3.format("($,.0f")(d.value);
                }); // Set the value as text, formatted as USD

            // Add text labels
            g.selectAll('.label')
                .data(data)
                .enter().append('text')
                .attr('class', 'label')
                .attr('x', d => x(d.key) + x.bandwidth() / 2) // Position in the center of the bar
                .attr('y', height + margin.bottom - 10) // Position at the bottom, adjust as needed
                .attr('text-anchor', 'middle') // Center the text
                .text(d => d.key); // Set the label text
        }
    }, [data]);

    return (
        <svg ref={d3Container} />
    );
};

export default BarGraph;
