import BarGraph from "./bargraph";

export default function CollegePageCosts ({
    INSTNM,
    currAlias, 
    totalCost, 
    netPrice, 
    tuitionInState, 
    tuitionOutState, 
    tuitionProgram,
    booksSupplies,
    roomBoardOn,
    roomBoardOff,
    otherExpenseOn,
    otherExpenseOff,
    otherExpenseFam,
    combCostData
}) {

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        }).format(value);
    };

    const avgScholarship = netPrice - totalCost;
    const sameTuition = tuitionInState === tuitionOutState;
    const stateDelta = tuitionOutState - tuitionInState;
    const livingExpenseOn = roomBoardOn + otherExpenseOn;

    return (
        <div>
            <h3>How much does it cost to attend 
                {currAlias && (
                            <span> {currAlias}?</span>
                )}
                {!currAlias && (
                            <span> {INSTNM}?</span>
                )}  
            </h3>
            <div>
                <span>The total costs of attending</span> 
                    {currAlias && (
                                <span> {currAlias} </span>
                    )}
                    {!currAlias && (
                                <span> {INSTNM} </span>
                    )} 
                includes tuition, living expenses and books and supplies. 
                <span> It's important to remember that you may qualify for scholarships or grants to decrease these annual costs.</span>

            </div>
            <h4>Annual Total Cost of Attending</h4>
                <div className="cp-costs">
                    <div className="cp-cost-label">Average Annual Cost: <span className="cp-cost-item">{formatCurrency(totalCost)}</span></div>
                    <div className="cp-cost-label">Average Scholarships/Grants: <span className="cp-grant-item">{formatCurrency(avgScholarship)}</span></div>
                    <div className="cp-cost-horizontal-divider"></div>
                    <div className="cp-cost-label">Average Net Annual Price: <span className="cp-cost-item">{formatCurrency(netPrice)}</span></div>
                </div>
            <h4>Tuition and Books</h4>
                {tuitionProgram && (
                <div className="cp-outcome-metrics">
                    <div>
                        <div>Tuition:</div>
                        <div className="cp-outcome-metric-value">{formatCurrency(tuitionProgram)}</div>
                    </div>
                    <div>
                        <div>Books:</div>
                        <div className="cp-outcome-metric-value">{formatCurrency(booksSupplies)}</div>
                    </div>
                </div>
                )}
                {!tuitionProgram && sameTuition && (
                    <div>
                        <div className="cp-outcome-metrics">
                            <div>
                                <div>Tuition:</div>
                                <div className="cp-outcome-metric-value">{formatCurrency(tuitionInState)}</div>
                            </div>
                            <div>
                                <div>Books:</div>
                                <div className="cp-outcome-metric-value">{formatCurrency(booksSupplies)}</div>
                            </div>
                        </div>
                        <div>Tuition at {INSTNM} is the same for in state and out of state students.</div>
                    </div>
                )}
                {!tuitionProgram && !sameTuition && (
                    <div>
                        <div className="cp-outcome-metrics">
                            <div>
                                <div>In State Tuition:</div>
                                <div className="cp-outcome-metric-value">{formatCurrency(tuitionInState)}</div>
                            </div>
                            <div>
                                <div>Out of State Tuition:</div>
                                <div className="cp-outcome-metric-value">{formatCurrency(tuitionOutState)}</div>
                            </div>
                            <div>
                                <div>Books:</div>
                                <div className="cp-outcome-metric-value">{formatCurrency(booksSupplies)}</div>
                            </div>
                        </div>
                        <div>Out of states students pay an additional <strong>{formatCurrency(stateDelta)}</strong> to attend {INSTNM} each year.</div>                        
                    </div>
                )}
            <h4>Living Expenses</h4>
                <BarGraph data={combCostData} />
                <div>Living expenses include both room and board and other expenses, where available.</div>
            {sameTuition && (
                <div>
                    <h4 className="cp-example-h4">Total Costs Before Aid: Living on Campus</h4>
                    <div className="cp-costs">
                        <div className="cp-cost-label">Tuition: <span className="cp-cost-item">{formatCurrency(tuitionInState || tuitionProgram)}</span></div>
                        {roomBoardOn && (
                        <div className="cp-cost-label">Living Expenses: <span className="cp-cost-item">{formatCurrency(livingExpenseOn)}</span></div>
                        )}
                        {booksSupplies && (
                            <div className="cp-cost-label">Books: <span className="cp-cost-item">{formatCurrency(booksSupplies)}</span></div>
                        )}
                        <div className="cp-cost-horizontal-divider"></div>
                        <div className="cp-cost-label">Total Costs Before Aid: <span className="cp-cost-item">{formatCurrency((tuitionInState || tuitionProgram) + livingExpenseOn + booksSupplies)}</span></div>
                    </div>
                    <h4 className="cp-example-h4">Total Costs Before Aid: Living at Home</h4>
                        <div className="cp-costs">
                            <div className="cp-cost-label">Tuition: <span className="cp-cost-item">{formatCurrency(tuitionInState || tuitionProgram)}</span></div>
                            {otherExpenseFam && (
                            <div className="cp-cost-label">Living Expenses: <span className="cp-cost-item">{formatCurrency(otherExpenseFam)}</span></div>
                            )}
                            {booksSupplies && (
                                <div className="cp-cost-label">Books: <span className="cp-cost-item">{formatCurrency(booksSupplies)}</span></div>
                            )}
                            <div className="cp-cost-horizontal-divider"></div>
                            <div className="cp-cost-label">Total Costs Before Aid: <span className="cp-cost-item">{formatCurrency((tuitionInState || tuitionProgram) + otherExpenseFam + booksSupplies)}</span></div>
                        </div>
                </div>
            )}
            {!sameTuition && (
                <div>
                    <h4 className="cp-example-h4">Total Costs Before Aid: Out of State Living on Campus</h4>
                        <div className="cp-costs">
                            <div className="cp-cost-label">Tuition: <span className="cp-cost-item">{formatCurrency(tuitionOutState || tuitionProgram)}</span></div>
                            {roomBoardOn && (
                            <div className="cp-cost-label">Living Expenses: <span className="cp-cost-item">{formatCurrency(roomBoardOn + otherExpenseOn)}</span></div>
                            )}
                            {booksSupplies && (
                                <div className="cp-cost-label">Books: <span className="cp-cost-item">{formatCurrency(booksSupplies)}</span></div>
                            )}
                            <div className="cp-cost-horizontal-divider"></div>
                            <div className="cp-cost-label">Total Costs Before Aid: <span className="cp-cost-item">{formatCurrency((tuitionOutState || tuitionProgram) + roomBoardOn + otherExpenseOn + booksSupplies)}</span></div>
                        </div>                       
                    <h4 className="cp-example-h4">Total Costs Before Aid: In State Living on Campus</h4>
                        <div className="cp-costs">
                            <div className="cp-cost-label">Tuition: <span className="cp-cost-item">{formatCurrency(tuitionInState || tuitionProgram)}</span></div>
                            {roomBoardOn && (
                            <div className="cp-cost-label">Living Expenses: <span className="cp-cost-item">{formatCurrency(roomBoardOn + otherExpenseOn)}</span></div>
                            )}
                            {booksSupplies && (
                                <div className="cp-cost-label">Books: <span className="cp-cost-item">{formatCurrency(booksSupplies)}</span></div>
                            )}
                            <div className="cp-cost-horizontal-divider"></div>
                            <div className="cp-cost-label">Total Costs Before Aid: <span className="cp-cost-item">{formatCurrency((tuitionInState || tuitionProgram) + roomBoardOn + otherExpenseOn + booksSupplies)}</span></div>
                        </div>                        
                    <h4 className="cp-example-h4">Total Costs Before Aid: In State Living at Home</h4>
                        <div className="cp-costs">
                            <div className="cp-cost-label">Tuition: <span className="cp-cost-item">{formatCurrency(tuitionInState || tuitionProgram)}</span></div>
                            {otherExpenseFam && (
                            <div className="cp-cost-label">Living Expenses: <span className="cp-cost-item">{formatCurrency(otherExpenseFam)}</span></div>
                            )}
                            {booksSupplies && (
                                <div className="cp-cost-label">Books: <span className="cp-cost-item">{formatCurrency(booksSupplies)}</span></div>
                            )}
                            <div className="cp-cost-horizontal-divider"></div>
                            <div className="cp-cost-label">Total Costs Before Aid: <span className="cp-cost-item">{formatCurrency((tuitionInState || tuitionProgram) + otherExpenseFam + booksSupplies)}</span></div>
                        </div>
                </div>
            )}
        </div>
    )

}