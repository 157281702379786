import useDocumentTitle from "../useDocumentTitle";
import LoginComponent from "../LoginComponent";

export default function LoginPage() {

    useDocumentTitle('Login');

    return (
        <LoginComponent />
    );
}