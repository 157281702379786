import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

export default function CollegeHeatmapD3({ heatmapData }) {
    const svgRef = useRef();
    const [tooltipData, setTooltipData] = useState(null);

    useEffect(() => {
        if (!heatmapData || heatmapData.length === 0) return;

        const renderHeatmap = () => {
            const containerWidth = svgRef.current.parentElement.offsetWidth;
            const isMobile = containerWidth < 768; // Define mobile threshold (e.g., 768px)
            const containerHeight = isMobile ? containerWidth * 4 : containerWidth * .7; // Flip aspect ratio for mobile .9


            // Mapping of original FIELD names to shorter versions
            const fieldNameMap = {
                "Agriculture (All Fields)": "Agriculture",
                "Architecture (All Fields)": "Architecture",
                "Area, Ethnic, Cultural, Gender and Group Studies (All Fields)": "Area Studies",
                "Biology (All Fields)": "Biology",
                "Business (All Fields)": "Business",
                "Communication and Journalism (All Fields)": "Communication",
                "Communications Technologies (All Fields)": "Comm Tech",
                "Computer and Information Sciences (All Fields)": "Computer Science",
                "Education (All Fields)": "Education",
                "Engineering (All Fields)": "Engineering",
                "Engineering Technicians (All Fields)": "Eng Tech",
                "English Language (All Fields)": "English",
                "Family and Human Sciences (All Fields)": "Family Science",
                "Fitness, Parks and Recreation (All Fields)": "Parks & Rec",
                "Foreign Languages (All Fields)": "Language",
                "History (All Fields)": "History",
                "Homeland Security, Law Enforcement and Firefighting (All Fields)": "Security",
                "Interdisciplinary Studies (All Fields)": "Interdisciplinary",
                "Legal Studies (All Fields)": "Law",
                "Liberal Arts and General Studies (All Fields)": "Liberal Arts",
                "Mathematics (All Fields)": "Math",
                "Natural Resources and Conservation (All Fields)": "Conservation",
                "Nursing and Health Professions (All Fields)": "Nursing",
                "Philosophy and Religious Studies (All Fields)": "Philosophy",
                "Physics, Chemistry and Geology (All Fields)": "Phys Sci",
                "Psychology (All Fields)": "Psychology",
                "Public Administration and Social Services (All Fields)": "Public Admin",
                "Social Sciences (All Fields)": "Social Science",
                "Theology (All Fields)": "Theology",
                "Visual and Performing Arts (All Fields)": "Arts",
            };
    

/*             const cipDescList = [
                "Business Administration, Management and Operations.",
                "Psychology, General.",
                "Biology, General.",
                "Registered Nursing, Nursing Administration, Nursing Research and Clinical Nursing.",
                "Accounting and Related Services.",
                "Teacher Education and Professional Development, Specific Levels and Methods.",
                "Communication and Media Studies.",
                "English Language and Literature, General.",
                "Criminal Justice and Corrections.",
                "Health and Physical Education/Fitness.",
                "Political Science and Government.",
                "History.",
                "Sociology.",
                "Marketing.",
                "Liberal Arts and Sciences, General Studies and Humanities.",
                "Fine and Studio Arts.",
                "Social Work.",
                "Finance and Financial Management Services.",
                "Teacher Education and Professional Development, Specific Subject Areas.",
                "Economics.",
                "Computer and Information Sciences, General.",
                "Mechanical Engineering.",
                "Mathematics.",
                "Computer Science.",
                "Design and Applied Arts.",
                "Electrical, Electronics and Communications Engineering.",
                "Drama/Theatre Arts and Stagecraft.",
                "Music.",
                "Romance Languages, Literatures, and Linguistics.",
                "Business/Commerce, General.",
                "Civil Engineering.",
                "Natural Resources Conservation and Research.",
                "Multi/Interdisciplinary Studies, Other.",
                "Communication Disorders Sciences and Services.",
                "Allied Health Diagnostic, Intervention, and Treatment Professions.",
                "Chemistry.",
                "Journalism.",
                "Anthropology.",
                "Special Education and Teaching.",
                "Health and Medical Administrative Services.",
                "Public Relations, Advertising, and Applied Communication.",
                "Chemical Engineering.",
                "Management Information Systems and Services.",
                "Human Development, Family Studies, and Related Services.",
                "Public Health.",
                "Computer Engineering.",
                "Human Resources Management and Services.",
                "Radio, Television, and Digital Communication.",
                "Hospitality Administration/Management.",
                "Film/Video and Photographic Arts.",
                "Rhetoric and Composition/Writing Studies.",
                "International Relations and National Security Studies.",
                "Health Services/Allied Health/Health Sciences, General.",
                "Geological and Earth Sciences/Geosciences.",
                "Geography and Cartography.",
                "Criminology.",
                "Biomedical/Medical Engineering.",
                "Architecture.",
                "International Business.",
                "Management Sciences and Quantitative Methods.",
                "Biochemistry, Biophysics and Molecular Biology.",
                "Information Science/Studies.",
                "Animal Sciences.",
                "Social Sciences, General.",
        ];
 */
            // Set up SVG dimensions
            const svgWidth = containerWidth;
            const svgHeight = containerHeight;
            const margin = { top: 20, right: 2, bottom: 20, left: 2 };
            const bannerHeight = 20; // Height of the banner at the top of each FIELD

            // Clear previous SVG content to avoid duplicating elements
            const svg = d3.select(svgRef.current)
                .attr('width', svgWidth)
                .attr('height', svgHeight)
                .attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`)
                .attr('preserveAspectRatio', 'xMinYMin meet'); // Keep the aspect ratio and scale to fit

            svg.selectAll("*").remove(); // Clear the SVG content

            // Calculate the min, max, and zero NPV values
            const npvValues = heatmapData.flatMap(d => d.items.map(i => i.median_npv));
            const minNpv = d3.min(npvValues);
            const maxNpv = d3.max(npvValues);

            // Define the custom color scale with 3 colors and zero-centered
            const colorScale = d3.scaleLinear()
                .domain([minNpv, 0, maxNpv])
                .range(["#F92654", "#414554", "#30CC5A"])
                .interpolate(d3.interpolateRgb);

            // Prepare the data for the outer treemap (FIELDs)
            const root = d3.hierarchy({ children: heatmapData })
                .sum(d => (d.items ? d3.sum(d.items, item => item.total_count) : 0));  // Sum total_count for each FIELD

            // Set up the outer treemap layout with binary tiling
            const outerTreemap = d3.treemap()
                .tile(d3.treemapBinary) // Use binary tiling to favor top-left placement
                .size([svgWidth - margin.left - margin.right, svgHeight - margin.top - margin.bottom])
                .paddingInner(6);

            // Generate the layout for FIELDs
            outerTreemap(root);

            // Render each FIELD as a rectangle
            const fields = root.leaves();

            fields.forEach(field => {
                const group = field.data;
                const shortFieldName = fieldNameMap[group.field] || group.field; // Get the short name or fall back to the original name
                const groupG = svg.append('g')
                    .attr('transform', `translate(${field.x0 + margin.left}, ${field.y0 + margin.top})`)
                    .attr('class', 'field-group') // Add class for field group
                    .on('mouseenter', function (event) {
                        d3.select(this).select('.field-rect')
                            .transition().duration(200)
                            .attr('stroke', '#05BFDD')
                            .attr('stroke-width', 5);
                        
                        // Show tooltip
                        tooltip
                            .text(group.field)
                            .style('visibility', 'visible')
                            .attr('x', event.pageX + 10) // Offset slightly from the cursor
                            .attr('y', event.pageY - 10);
                    })
                    .on('mousemove', function (event) {
                        // Move the tooltip with the mouse
                        tooltip
                            .attr('x', event.pageX + 10)
                            .attr('y', event.pageY - 10);
                    })
                    .on('mouseleave', function () {
                        d3.select(this).select('.field-rect')
                            .transition().duration(200)
                            .attr('stroke', 'black')  // Reset to original stroke
                            .attr('stroke-width', 1);

                        // Hide tooltip
                        tooltip.style('visibility', 'hidden');
                    });

                // Render the FIELD box
                groupG.append('rect')
                    .attr('class', 'field-rect')
                    .attr('width', field.x1 - field.x0)
                    .attr('height', field.y1 - field.y0)
                    .attr('fill', '#ddd') // Light gray background for FIELD group
                    .attr('stroke', '#000933')
                    .attr('stroke-width', 1);

                // Render the banner at the top of the FIELD
                groupG.append('rect')
                    .attr('class', 'field-banner')
                    .attr('width', field.x1 - field.x0)
                    .attr('height', bannerHeight)
                    .attr('fill', 'white'); // Navy color for the banner

                // Add the FIELD name text centered vertically in the banner
                const textElement = groupG.append('text')
                    .attr('x', 5)
                    .attr('y', bannerHeight / 2)
                    .attr('text-anchor', 'start')
                    .attr('dominant-baseline', 'middle')
                    .attr('font-size', '0.8rem')
                    .attr('font-weight', 'bold')
                    .attr('fill', '#000933')
                    .text(shortFieldName);

                // Manually truncate text if it overflows the banner
                const bannerWidth = field.x1 - field.x0 - 10; // Subtract padding from the right side
                truncateText(textElement, bannerWidth);

                function truncateText(textElement, maxWidth) {
                    let textContent = textElement.text();
                    let textLength = textElement.node().getComputedTextLength();

                    while (textLength > maxWidth && textContent.length > 0) {
                        textContent = textContent.slice(0, -1);
                        textElement.text(textContent + '...');
                        textLength = textElement.node().getComputedTextLength();
                    }
                }

                // Adjust the available space for CIPDESCs by subtracting the banner height
                const availableHeight = field.y1 - field.y0 - bannerHeight;

                // Prepare the data for the inner treemap (CIPDESCs within this FIELD)
                const innerRoot = d3.hierarchy({ children: group.items || [] }) // Ensure children exist
                    .sum(d => d.total_count);

                // Set up the inner treemap layout for the CIPDESC components with binary tiling
                const innerTreemap = d3.treemap()
                    .tile(d3.treemapBinary) // Use binary tiling for inner treemap
                    .size([field.x1 - field.x0, availableHeight])
                    .paddingInner(2);

                // Generate the layout for CIPDESC components
                innerTreemap(innerRoot);

                // Render each CIPDESC as a rectangle within the FIELD, below the banner
                innerRoot.leaves().forEach(leaf => {
                    const blockG = groupG.append('g')
                        .attr('transform', `translate(${leaf.x0}, ${leaf.y0 + bannerHeight})`);

                    const rectWidth = leaf.x1 - leaf.x0;
                    const rectHeight = leaf.y1 - leaf.y0;

                    blockG.append('rect')
                        .attr('width', rectWidth)
                        .attr('height', rectHeight)
                        .attr('fill', colorScale(leaf.data.median_npv))
                        .attr('stroke', 'none')
                        .attr('cursor', 'pointer')
                        .on('click', function (event) {
                            // Show tooltip with CIPDESC details
                            //console.log('leaf.data:', leaf.data);
                            setTooltipData({
                                CIPDESC: leaf.data.CIPDESC,
                                median_npv: `$${(leaf.data.median_npv / 1000).toFixed(1)}k`,
                                total_count: leaf.data.total_count,
                                x: event.pageX,
                                y: event.pageY,
                                CIPCODE: leaf.data.CIPCODE,
                                CODE: leaf.data.CODE,
                            });
                        });

                    // if (cipDescList.includes(leaf.data.CIPDESC)) {
                    //     // Call function to wrap text
                    //     wrapText(blockG, leaf.data.CIPDESC, rectWidth, rectHeight);
                    // }

                    wrapText(blockG, leaf.data.CIPDESC, rectWidth, rectHeight);

                    // Add median_npv value centered in the rectangle, formatted as $, with "k" for thousands
                    const fontSize = Math.min(rectWidth, rectHeight) / 6;
                    if (fontSize >= 6) {
                        blockG.append('text')
                            .attr('x', rectWidth / 2)
                            .attr('y', rectHeight / 2)
                            .attr('text-anchor', 'middle')
                            .attr('alignment-baseline', 'middle')
                            .text(`$${(leaf.data.median_npv / 1000).toFixed(1)}k`)
                            .attr('font-size', `${fontSize}px`)
                            .attr('fill', 'white')
                            .attr('font-weight', 'bold')
                            .attr('cursor', 'pointer')
                            .on('click', function (event) {
                                // Show tooltip with CIPDESC details
                                setTooltipData({
                                    CIPDESC: leaf.data.CIPDESC,
                                    median_npv: `$${(leaf.data.median_npv / 1000).toFixed(1)}k`,
                                    total_count: leaf.data.total_count,
                                    x: event.pageX,
                                    y: event.pageY,
                                    CIPCODE: leaf.data.CIPCODE,
                                    CODE: leaf.data.CODE,
                                });
                            });
                    }
                });
            });

            // Create a text element for the tooltip (FIELD name) and append it last to ensure it's on top
            const tooltip = svg.append('g')
                .attr('class', 'tooltip-group')
                .style('visibility', 'hidden'); // Initially hidden

            tooltip.append('rect')
                .attr('width', 100)
                .attr('height', 30)
                .attr('fill', 'white')
                .attr('stroke', 'black')
                .attr('rx', 5)
                .attr('ry', 5); // Rounded corners

            const tooltipText = tooltip.append('text')
                .attr('x', 10)
                .attr('y', 20)
                .attr('font-size', '14px')
                .attr('fill', 'black');

            // Tooltip positioning logic
            svg.on('mousemove', (event) => {
                tooltip.attr('transform', `translate(${event.pageX + 10}, ${event.pageY - 30})`);
            });
        };

        renderHeatmap();

        // Redraw on window resize
        window.addEventListener('resize', renderHeatmap);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', renderHeatmap);
        };

    }, [heatmapData]);

    return (
        <div>
            <svg ref={svgRef}></svg>
            {tooltipData && (
                <div
                    style={{
                        position: 'absolute',
                        top: tooltipData.y + 'px', // Adjust y position
                        left: `${Math.max(Math.min(tooltipData.x, window.innerWidth - 350), 10)}px`, // Adjust x position with a minimum left margin
                        width: '300px', // Set tooltip width to 300px
                        background: 'white',
                        border: '1px solid black',
                        borderRadius: '5px',
                        padding: '10px',
                        pointerEvents: 'auto', // Allow interaction with the tooltip
                        zIndex: 1000, // Ensure the tooltip is on top
                        wordWrap: 'break-word', // Ensure long text wraps within the tooltip
                        overflow: 'hidden', // Ensure content doesn't overflow
                        boxSizing: 'border-box', // Ensure padding and border are included in the element's width and height
                        display: 'flex',
                        flexDirection: 'column', // Use flexbox to layout content
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between', // Ensure space between title and close button
                            alignItems: 'center', // Vertically center content
                            marginBottom: '10px', // Space below the header
                        }}
                    >
                        <h4 style={{ margin: 0 }}>{tooltipData.CIPDESC}</h4>
                        <button
                            onClick={() => setTooltipData(null)}
                            style={{
                                background: 'transparent',
                                border: 'none',
                                fontSize: '30px',
                                cursor: 'pointer',
                                marginLeft: '10px',
                                marginRight: '10px',
                                width: '30px', // Add some space between the title and button
                            }}
                        >
                            &times;
                        </button>
                    </div>
                    <div><strong>Median ROI:</strong> {tooltipData.median_npv}</div>
                    <div style={{ marginBottom: '10px' }}><strong>Total Ranked:</strong> {tooltipData.total_count}</div>
                    <div>
                        <a className="heatmap-cip-link" href={`/programrankings/?pcip=${tooltipData.CODE}&cip=${tooltipData.CIPCODE}&page=1&sort=rank_desc`}>View ROI rankings</a>
                    </div>
                </div>
            )}
        </div>
    );

    // Function to wrap text within the given width
    function wrapText(selection, text, width, height) {
        const fontSize = Math.min(width, height) / 12; // Calculate font size based on rect size
    
        // Only render text if the calculated font size is 6px or larger
        if (fontSize < 5) return;
    
        const words = text.replace(/\./g, '').split(/\s+/).reverse();
        let word;
        let line = [];
        let lineNumber = 0;
        const lineHeight = 1.1; // Adjust line height as needed
        const y = 5; // Start y position (slightly padded)
        const x = 5; // x position (slightly padded)
        const dy = 0; // Adjustment for y
        let tspan = selection.append("text")
            .attr("x", x)
            .attr("y", y)
            .attr("dy", `${dy}em`)
            .attr('font-size', `${fontSize}px`) // Use calculated font size
            .attr('fill', 'white')
            .attr('text-anchor', 'start')
            .attr('alignment-baseline', 'hanging')
            .text(null);
    
        while (word = words.pop()) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                if ((y + (++lineNumber * lineHeight * fontSize)) < height) {
                    tspan = selection.append("text")
                        .attr("x", x)
                        .attr("y", y)
                        .attr("dy", `${lineNumber * lineHeight}em`)
                        .attr('font-size', `${fontSize}px`) // Use calculated font size
                        .attr('fill', 'white')
                        .attr('text-anchor', 'start')
                        .attr('alignment-baseline', 'hanging')
                        .text(word);
                } else {
                    break;
                }
            }
        }
    }
}    
