import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const FOSSearchComponent = ({ onSearchResults, currentPCIP, currentCIP, sortOption, onUpdateURL }) => {
  const [query, setQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false); // State to track input focus
  const [initialQuery, setInitialQuery] = useState('');
  const inputRef = useRef(null); // Ref for the input element
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_API_URL || '';

  // Initialize query from URL parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = decodeURIComponent(params.get('query') || '');
    setQuery(searchQuery); // Set the search input to match the URL query parameter.
    setInitialQuery(searchQuery);
  }, [location.search]);

  // Debounced search effect
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
        // Adjust logic to handle empty query as a valid change
        if (query.length >= 4 && query !== initialQuery || (query === '' && initialQuery !== '')) {
          handleSearch();
          // Directly update URL if the query is cleared or significantly changed
          onUpdateURL(query);
        }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
}, [query, isFocused]);


  const handleSearch = async () => {
    //const url = `${apiUrl}/college?query=${encodeURIComponent(query)}&state=${encodeURIComponent(selectedState)}`;
    const url = `${apiUrl}/fieldofstudy?&query=${encodeURIComponent(query)}&sort=${sortOption}&pcip=${currentPCIP}&cip=${currentCIP}`;

    
    try {
        const response = await fetch(url);
        if (response.ok) {
            const searchResults = await response.json();
            onSearchResults(searchResults);
            // Call onUpdateURL regardless of query being empty to ensure URL is updated
            onUpdateURL(query);
        } else {
            console.error('Failed to fetch search results');
        }
    } catch (error) {
        console.error(error);
    }
  };

  const clearSearch = () => setQuery('');

  return (
    <div className="search-wrapper">
      <input
        className="search-text"
        ref={inputRef}
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onFocus={() => setIsFocused(true)} // Set focus state to true when input is focused
        onBlur={() => setIsFocused(false)} // Reset focus state when input loses focus
        placeholder="Search colleges by name..."
        // Optionally handle Enter key to search immediately
        onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
      />
      {query && (
        <div className="clear-search-wrapper">
          <button onClick={clearSearch} className="clear-search">
            X
          </button>
        </div>
      )}
    </div>
  );
};

export default FOSSearchComponent;
