import { useNavigate,useParams,useLocation } from "react-router-dom";
import { useState,useEffect } from "react";
import BarGraph from "../bargraph";
import CollegePageCard from "../CollegePageCard";
import CollegePageDescription from "../CollegePageDescription";
import CollegePageSelectivity from "../CollegePageSelectivity";
import CollegePageOutcomes from "../CollegePageOutcomes";
import CollegePageCosts from "../CollegePageCosts";
import CollegePageStudies from "../CollegePageStudies";
import useDocumentTitle from "../useDocumentTitle";

export default function CollegePage() {
    const location = useLocation();
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    const query = useQuery();
    const INSTNM = query.get("INSTNM");
    const UNITID = query.get("UNITID");
    useDocumentTitle(INSTNM);

    //Page data variables  
    const [currName,setName] = useState();
    const [currAlias,setAlias] = useState();
    const [currURL,setURL] = useState();
    const [currType,setType] = useState();
    const [currCity,setCity] = useState();
    const [currState,setState] = useState();
    const [currDeg,setDeg] = useState();
    const [currRegion,setRegion] = useState();
    const [currLocale,setLocale] = useState();
    const [currUGDS,setUGDS] = useState();
    const [rawADM,setRawADM] = useState();
    const [currADM,setADM] = useState();
    const [currCMP,setCMP] = useState();
    const [currNCMP,setNCMP] = useState();
    const [currDebt,setDebt] = useState();
    const [currIncome,setIncome] = useState();
    const [currWDebt,setWDebt] = useState();
    const [currDefault,setDefault] = useState();
    const [totalCost, setTotalCost] = useState();
    const [netPrice, setNetPrice] = useState();
    const [tuitionInState, setTuitionInState] = useState();
    const [tuitionOutState, setTuitionOutState] = useState();
    const [tuitionProgram, setTuitionProgram] = useState();
    const [booksSupplies, setBooksSupplies] = useState();
    const [roomBoardOn, setRoomBoardOn] = useState();
    const [roomBoardOff, setRoomBoardOff] = useState();
    const [otherExpenseOn, setOtherExpenseOn] = useState();
    const [otherExpenseOff, setOtherExpenseOff] = useState();
    const [otherExpenseFam, setOtherExpenseFam] = useState();
    const [combCostData, setCombCostData] = useState();
    const [defaultRate3, setDefaultRate3] = useState();
    const [hseThreshold8, setHseThreshold8] = useState();
    const [pctFedLoan, setPctFedLoan] = useState(null);
    
    //rank data variables
    const [currRank,setRank] = useState();
    const [act25, setAct25] = useState('');
    const [act75, setAct75] = useState('');
    const [sat25, setSat25] = useState('');
    const [sat75, setSat75] = useState('');
    const [netNPV, setNetNPV] = useState('');
    const [compRate, setCompRate] = useState('');
    const [gradIncome4, setGradIncome4] = useState('');
    const [gradDebt, setGradDebt] = useState('');
    const [nonGradDebt, setNonGradDebt] = useState('');

    //FOS variables
    const [PCIP01, setPCIP01] = useState();
    const [PCIP03, setPCIP03] = useState();
    const [PCIP04, setPCIP04] = useState();
    const [PCIP05, setPCIP05] = useState();
    const [PCIP09, setPCIP09] = useState();
    const [PCIP10, setPCIP10] = useState();
    const [PCIP11, setPCIP11] = useState();
    const [PCIP12, setPCIP12] = useState();
    const [PCIP13, setPCIP13] = useState();
    const [PCIP14, setPCIP14] = useState();
    const [PCIP15, setPCIP15] = useState();
    const [PCIP16, setPCIP16] = useState();
    const [PCIP19, setPCIP19] = useState();
    const [PCIP22, setPCIP22] = useState();
    const [PCIP23, setPCIP23] = useState();
    const [PCIP24, setPCIP24] = useState();
    const [PCIP25, setPCIP25] = useState();
    const [PCIP26, setPCIP26] = useState();
    const [PCIP27, setPCIP27] = useState();
    const [PCIP29, setPCIP29] = useState();
    const [PCIP30, setPCIP30] = useState();
    const [PCIP31, setPCIP31] = useState();
    const [PCIP38, setPCIP38] = useState();
    const [PCIP39, setPCIP39] = useState();
    const [PCIP40, setPCIP40] = useState();
    const [PCIP41, setPCIP41] = useState();
    const [PCIP42, setPCIP42] = useState();
    const [PCIP43, setPCIP43] = useState();
    const [PCIP44, setPCIP44] = useState();
    const [PCIP45, setPCIP45] = useState();
    const [PCIP46, setPCIP46] = useState();
    const [PCIP47, setPCIP47] = useState();
    const [PCIP48, setPCIP48] = useState();
    const [PCIP49, setPCIP49] = useState();
    const [PCIP50, setPCIP50] = useState();
    const [PCIP51, setPCIP51] = useState();
    const [PCIP52, setPCIP52] = useState();
    const [PCIP54, setPCIP54] = useState();

    const apiUrl = process.env.REACT_APP_API_URL || '';
    const blobUrl = process.env.REACT_APP_BLOB_URL || 'https://collegenpvcloudstoredev.blob.core.windows.net/images';

    const imageUrl = `${UNITID}.jpg`;

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const schoolTypeMap = {
        0: "not classified",
        1: "certificate granting school",
        2: "associate's degree granting university",
        3: "bachelor's degree granting university",
        4: "graduate degree granting university",
      };
    
    const regionMap = {
        0: "",
        1: "the new england",
        2: "the mideastern",
        3: "the great lakes",
        4: "the plains",
        5: "the southeastern",
        6: "the southwestern",
        7: "the rocky mountain",
        8: "the west coast",
        9: "the outlying area",
      };

      const localeMap = {
        11: "city of",
        12: "city of",
        13: "city of",
        21: "suburb of",
        22: "suburb of",
        23: "suburb of",
        31: "town of",
        32: "town of",
        33: "town of",
        41: "rural town of",
        42: "rural town of",
        43: "rural town of",
      };

    const FETCHINSTNM = INSTNM.replace(/\//g, "");

    useEffect(() => {
        fetch(`${apiUrl}/college/${FETCHINSTNM}/${UNITID}`)
            .then(response => {
                response.json().then(uniData => {
                    console.log(uniData);
                    setName(uniData.INSTNM);
                    setAlias(uniData.ALIAS);
                    setURL(uniData.INSTURL);
                    if (uniData.CONTROL_PEPS === "Proprietary") {
                        setType("for-profit");
                    } else if (uniData.CONTROL_PEPS === "Private Nonprofit") {
                        setType("private non-profit");
                    } else if (uniData.CONTROL_PEPS !== null) {
                        setType(uniData.CONTROL_PEPS.toLowerCase());
                    } else {
                        setType("");
                    };
                    setCity(uniData.CITY);
                    setState(uniData.STABBR);
                    setDeg(schoolTypeMap[uniData.PREDDEG]);
                    setRegion(regionMap[uniData.REGION]);
                    setLocale(localeMap[uniData.LOCALE]);
                    setUGDS(uniData.UGDS.toLocaleString('en-us'));
                    setRawADM(uniData.ADM_RATE);
                    const admRate = uniData.ADM_RATE !== null ? (uniData.ADM_RATE).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1,  maximumFractionDigits: 1 }) : null;
                    setADM(admRate);
                    // const compRate = uniData.C100_4 !== null ? (uniData.C100_4).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1,  maximumFractionDigits: 1 }) : null;
                    // setCMP(compRate);
                    // const nocompRate = uniData.C150_4 !== null ? (1-uniData.C150_4).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1,  maximumFractionDigits: 1 }) : null;
                    // setNCMP(nocompRate);
                    setDebt(uniData.GRAD_DEBT_MDN);
                    setIncome(uniData.MD_EARN_WNE_P10);
                    setWDebt(uniData.WDRAW_DEBT_MDN);
                    const defaultRate = uniData.CDR3 !== null ? (uniData.CDR3).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 1,  maximumFractionDigits: 1 }) : null;
                    setDefault(defaultRate);
                    setTotalCost(uniData.COSTT4_A || uniData.COSTT4_P);
                    setNetPrice(uniData.NPT4_PRIV || uniData.NPT4_PUB);
                    setTuitionInState(uniData.TUITIONFEE_IN);
                    setTuitionOutState(uniData.TUITIONFEE_OUT);
                    setTuitionProgram(uniData.TUITIONFEE_PROG);
                    setBooksSupplies(uniData.BOOKSUPPLY);
                    setRoomBoardOn(uniData.ROOMBOARD_ON);
                    setRoomBoardOff(uniData.ROOMBOARD_OFF);
                    setOtherExpenseOn(uniData.OTHEREXPENSE_ON);
                    setOtherExpenseOff(uniData.OTHEREXPENSE_OFF);
                    setOtherExpenseFam(uniData.OTHEREXPENSE_FAM);
                    const newCombCostData = [
                        { key: 'On Campus', value: (uniData.ROOMBOARD_ON + uniData.OTHEREXPENSE_ON || 0 ) },
                        { key: 'Off Campus', value: (uniData.ROOMBOARD_OFF + uniData.OTHEREXPENSE_OFF || 0 ) },
                        { key: 'At Home', value: uniData.OTHEREXPENSE_FAM || 0 }
                    ];
                    setCombCostData(newCombCostData);
                    setDefaultRate3(uniData.CDR3);
                    setHseThreshold8(uniData.GT_THRESHOLD_P8);
                    setPctFedLoan(uniData.PCTFLOAN);
                });
    });
    }, []);

    useEffect(() => {
        fetch(`${apiUrl}/college/${UNITID}`)
            .then(response => {
                response.json().then(uniOutcomeData => {
                    //console.log(uniOutcomeData);
                    setAct25(uniOutcomeData.ACTCM25);
                    setAct75(uniOutcomeData.ACTCM75);
                    setSat25(uniOutcomeData.SATCM25);
                    setSat75(uniOutcomeData.SATCM75);
                    setRank(uniOutcomeData.RANK);
                    setNetNPV(uniOutcomeData.NET_NPV);
                    setCompRate(uniOutcomeData.C150);
                    setGradIncome4(uniOutcomeData.MD_EARN_WNE_4YR);
                    setGradDebt(uniOutcomeData.GRAD_DEBT_MDN);
                    setNonGradDebt(uniOutcomeData.WDRAW_DEBT_MDN);
                });
            });
    }, []);

    useEffect(() => {
        fetch(`${apiUrl}/collegefos/${UNITID}`)
            .then(response => {
                response.json().then(uniFosData => {
                    //console.log(uniFosData);
                    setPCIP01(uniFosData.PCIP01);
                    setPCIP03(uniFosData.PCIP03);
                    setPCIP04(uniFosData.PCIP04);
                    setPCIP05(uniFosData.PCIP05);
                    setPCIP09(uniFosData.PCIP09);
                    setPCIP10(uniFosData.PCIP10);
                    setPCIP11(uniFosData.PCIP11);
                    setPCIP12(uniFosData.PCIP12);
                    setPCIP13(uniFosData.PCIP13);
                    setPCIP14(uniFosData.PCIP14);
                    setPCIP15(uniFosData.PCIP15);
                    setPCIP16(uniFosData.PCIP16);
                    setPCIP19(uniFosData.PCIP19);
                    setPCIP22(uniFosData.PCIP22);
                    setPCIP23(uniFosData.PCIP23);
                    setPCIP24(uniFosData.PCIP24);
                    setPCIP25(uniFosData.PCIP25);
                    setPCIP26(uniFosData.PCIP26);
                    setPCIP27(uniFosData.PCIP27);
                    setPCIP29(uniFosData.PCIP29);
                    setPCIP30(uniFosData.PCIP30);
                    setPCIP31(uniFosData.PCIP31);
                    setPCIP38(uniFosData.PCIP38);
                    setPCIP39(uniFosData.PCIP39);
                    setPCIP40(uniFosData.PCIP40);
                    setPCIP41(uniFosData.PCIP41);
                    setPCIP42(uniFosData.PCIP42);
                    setPCIP43(uniFosData.PCIP43);
                    setPCIP44(uniFosData.PCIP44);
                    setPCIP45(uniFosData.PCIP45);
                    setPCIP46(uniFosData.PCIP46);
                    setPCIP47(uniFosData.PCIP47);
                    setPCIP48(uniFosData.PCIP48);
                    setPCIP49(uniFosData.PCIP49);
                    setPCIP50(uniFosData.PCIP50);
                    setPCIP51(uniFosData.PCIP51);
                    setPCIP52(uniFosData.PCIP52);
                    setPCIP54(uniFosData.PCIP54);
                });
            });
    }, []);

    return (
        <div className="collegepage-main">
            {/* <button onClick={handleBack} className="back-button">Go Back</button> */}
            <CollegePageCard 
                INSTNM={INSTNM} 
                UNITID={UNITID} 
                currAlias={currAlias} 
                currName={currName} 
                currURL={currURL}
                currRank={currRank} 
            />
            <CollegePageDescription 
                currName={currName} 
                currAlias={currAlias} 
                currRegion={currRegion} 
                currLocale={currLocale} 
                currCity={currCity} 
                currState={currState} 
                currType={currType} 
                currDeg={currDeg} 
                currUGDS={currUGDS}
            />
            <div className="horizontal-divider"></div>
            <CollegePageOutcomes 
                UNITID={UNITID}
                netNPV={netNPV}
                currName={currName} 
                currAlias={currAlias}
                currRank={currRank} 
                compRate={compRate}
                gradIncome4={gradIncome4}
                gradDebt={gradDebt}
                nonGradDebt={nonGradDebt}
                defaultRate3={defaultRate3}
                hseThreshold8={hseThreshold8}
                pctFedLoan={pctFedLoan}
            />
            <div className="horizontal-divider"></div>
            <CollegePageSelectivity 
                currName={currName} 
                currAlias={currAlias} 
                currADM={currADM} 
                rawADM={rawADM}
                act25={act25}
                act75={act75}
                sat25={sat25}
                sat75={sat75}
            />
            <div className="horizontal-divider"></div>
            <CollegePageCosts 
                INSTNM={INSTNM}
                currAlias={currAlias}
                totalCost={totalCost} 
                netPrice={netPrice}
                tuitionInState={tuitionInState}
                tuitionOutState={tuitionOutState}
                tuitionProgram={tuitionProgram}
                booksSupplies={booksSupplies}
                roomBoardOn={roomBoardOn}
                roomBoardOff={roomBoardOff}
                otherExpenseOn={otherExpenseOn}
                otherExpenseOff={otherExpenseOff}
                otherExpenseFam={otherExpenseFam}
                combCostData={combCostData}
            />
            <div className="horizontal-divider"></div>
            <CollegePageStudies 
                currName={currName} 
                currAlias={currAlias}
                PCIP01={PCIP01}
                PCIP03={PCIP03}
                PCIP04={PCIP04}
                PCIP05={PCIP05}
                PCIP09={PCIP09}
                PCIP10={PCIP10}
                PCIP11={PCIP11}
                PCIP12={PCIP12}
                PCIP13={PCIP13}
                PCIP14={PCIP14}
                PCIP15={PCIP15}
                PCIP16={PCIP16}
                PCIP19={PCIP19}
                PCIP22={PCIP22}
                PCIP23={PCIP23}
                PCIP24={PCIP24}
                PCIP25={PCIP25}
                PCIP26={PCIP26}
                PCIP27={PCIP27}
                PCIP29={PCIP29}
                PCIP30={PCIP30}
                PCIP31={PCIP31}
                PCIP38={PCIP38}
                PCIP39={PCIP39}
                PCIP40={PCIP40}
                PCIP41={PCIP41}
                PCIP42={PCIP42}
                PCIP43={PCIP43}
                PCIP44={PCIP44}
                PCIP45={PCIP45}
                PCIP46={PCIP46}
                PCIP47={PCIP47}
                PCIP48={PCIP48}
                PCIP49={PCIP49}
                PCIP50={PCIP50}
                PCIP51={PCIP51}
                PCIP52={PCIP52}
                PCIP54={PCIP54}
            />
        </div>
    );
}