import React, { useState,useEffect } from 'react';
import useDocumentTitle from '../useDocumentTitle';

export default function AccountPage() {

    const apiUrl = process.env.REACT_APP_API_URL || '';
    useDocumentTitle('Account');

    const [savedColleges, setSavedColleges] = useState([]);
    const [collegeDetails, setCollegeDetails] = useState([]);

    useEffect(() => {
            // Function to fetch saved colleges
        const fetchSavedColleges = async () => {
            try {
                const response = await fetch(`${apiUrl}/get-saved-colleges`, {
                    method: 'GET',
                    credentials: 'include', // Necessary for cookies to be sent with the request
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log("Saved Colleges:", data);
                setSavedColleges(data);
                fetchCollegeDetails(data.map(college => college.UNITID));
            } catch (error) {
                console.error("Error fetching saved colleges:", error);
            }
        };
        fetchSavedColleges();
    }, []);

    const fetchCollegeDetails = async (unitIds) => {
        try {
            const response = await fetch(`${apiUrl}/colleges/info`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ unitIds }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const details = await response.json();
            console.log("College Details:", details);
            setCollegeDetails(details);
            console.log(collegeDetails);
        } catch (error) {
            console.error("Error fetching college details:", error);
        }
    };

    return (
        <div className="account-main">
            <h2>Welcome back!</h2>
                <h3>Your Saved Colleges</h3>
                {collegeDetails.length > 0 ? (
                    <ul>
                        {collegeDetails.map((college, index) => (
                            <li key={index}>Name: {college.INSTNM}</li>
                        ))}
                    </ul>
                ) : (
                    <p>You have not saved any colleges yet.</p>
                )}
        </div>
    );
}